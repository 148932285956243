import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import styled, { css } from 'react-emotion'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { Pager } from '../utils/styles'
import Img from 'gatsby-image'
import Seo from '../components/Seo'
import { mq, colors, PageHeadings } from '../utils/styles'
import SharePage from '../components/SharePage'
import Player from '../components/Player'

const Post = styled('div')`
  display: flex;
  justify-content: center;
  ${mq({
    'flex-wrap': ['wrap', 'nowrap', 'nowrap', 'nowrap'],
    'padding-bottom': ['0rem', '0.5rem', '0.5rem', '0.5rem'],
  })};
`
const PostContent = styled('div')`
  ${mq({
    'padding-left': ['0rem', '1rem', '1rem', '1rem'],
  })};
`

const ImagePost = styled('div')`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`
const EpisodeText = styled('div')`
  display: block;
  padding-bottom: 1rem;
  margin-top: 1rem;
`

class EpisodeTemplate extends React.Component {
  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.track
    ) {
      try {
        typeof window !== 'undefined' &&
          window.gtag('event', 'conversion', {
            send_to: 'AW-972785904/l1l4CNb-sJABEPCR7s8D',
          })
      } catch (e) {}
    }
  }

  render() {
    const episode = this.props.data.allFeedPodcastFeed.edges[0].node
    return (
      <Layout location={this.props.location}>
        <Seo
          type="post"
          image={episode.fields.localImage.childImageSharp.fluid.src}
          title={episode.title}
          description={
            episode.content.replace(/<(?:.|\n)*?>/gm, '').substring(0, 147) +
            '...'
          }
          path={this.props.pageContext.slug}
        />
        <EpisodeText>
          <h1>{episode.title}</h1>
          <small>{episode.rawDate}</small>
          <div
            className={css`
              padding: 0.7rem;
            `}
          >
            <Img fluid={episode.fields.localImage.childImageSharp.fluid} />
          </div>

          <p dangerouslySetInnerHTML={{ __html: episode.content }} />
        </EpisodeText>
        <Player width="100%" height="200" link={episode.link} />

        <Link
          className={css`
            text-decoration: none;
          `}
          to={'/episodes/'}
        >
          View more episodes
        </Link>
        <h3
          className={css`
            margin-top: 1rem;
            margin-bottom: 1rem;
          `}
        >
          Share this with other hippies
        </h3>

        <SharePage
          title={episode.title}
          url={`${this.props.data.site.siteMetadata.siteUrl}${
            episode.fields.slug
          }`}
        />
      </Layout>
    )
  }
}

export default EpisodeTemplate

export const indexQuery = graphql`
  query EpisodeBySlug($slug: String!) {
    site {
      siteMetadata {
        siteName
        siteDescription
        siteUrl
      }
    }

    # allRssEntry(filter: { fields: {slug: { eq: $slug } }}){
    #   edges{
    #     node{
    #       title
    #       description
    #       image
    #       html
    #       rawDate(formatString: "MMMM D, YYYY")
    #       pubDate
    #       link
    #       fields{
    #         slug
    #         localImage{
    #           relativePath
    #           childImageSharp{
    #             fluid(maxWidth: 600 maxHeight: 600) {
    #               ...GatsbyImageSharpFluid
    #               }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }

    allFeedPodcastFeed(filter: { fields: {slug: { eq: $slug } }}) {
    edges {
      node {
        content
        contentSnippet
        link
        pubDate
        title
        itunes {
          duration
          image {
            attrs {
              href
            }
          }
        }
        fields{
            slug
            localImage{
              relativePath
              childImageSharp{
                fluid(maxWidth: 600 maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                  }
              }
            }
          }
        id
      }
    }
  }

    art: file(sourceInstanceName: {eq: "assets"} relativePath: { eq: "Kudakwashe_Samkange.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # cropFocus: CENTER
        fluid(maxWidth: 600 maxHeight: 600) {
          ...GatsbyImageSharpFluid
          }
      }
    }
    allFile(filter: {sourceInstanceName: {eq: "pages"}, absolutePath: {regex:"/\\.(gif|jpg|jpeg|tiff|png)$/"}}) {
      edges {
        node {
          relativePath
          childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          # cropFocus: CENTER
          fixed(width: 200, height: 180) {
            ...GatsbyImageSharpFixed
           }
          }
        }
      }
    }
  }
`

// allEpisodes(filter: { fields: {slug: { eq: $slug } }}) {
//   edges{
//     node{
//       title
//       date(formatString: "MMMM D, YYYY")
//       description
//       fields{
//         slug
//         file{
//           publicURL
//         }
//       }
//     }
//   }
// }
